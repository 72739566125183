.main{
  padding: 20px;
  
}
.heading{
  font-size: 2rem;
  font-weight: 900;
  margin-top: 5vh;
}
.discription{
  font-size: 1rem;
  font-weight: 400;
  margin-top: 4vh;
  margin-bottom: 5vh;
}
.fot{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 900;
  margin-top: 4vh;
}
.nnnnnnnnnn{
  height:5vh;
  background:red;
  margin:10px;
  width:40%
}