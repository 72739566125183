

.App {
  background:#060517 ;
  color: white;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.bluetickk{
  height: 80%;
  width: 70%;
}

.header{
  height: 9vh;
  width: 100%;
  background: #28273f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  position: -webkit-sticky; /* Safari */
  position: fixed;
  top: 0;
  z-index: 3;
}
.head-text{
  font-family: Times New Roman;
  color: white;
  margin-left: 14px;
  color: #a1f993c5;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
}
.drawee-icon{
  margin-right: 14px;
}


.Home{
  height: 80vh;
  width: 100vw;
  background: #06051700;
  display: flex;
  overflow:hidden;
  justify-content: center;
  
}
.project{
  height: 162vh;
  width: 100vw;
  background: #1c2542;
  
}
.about{
height: 220vh;
  width: 100vw;
  background: #020f20;
  
}
a{
  text-decoration: none;
  color: white;
  font-family: Sans-Serif;
  font-weight: bold;
  
}
.contact{
height: 110vh;
  width: 100vw;
  background: #020f20;
}
.desk-head{
  opacity:0;
  display:flex;
}
.dr-about{
  
}
.dr-menu-list{
  display:flex;
  font-size:1.3rem;
  font-weight: 800;
  margin:10vw;
  
  align-items:center;
  width:100%;
  height:7vh;
  
}
.dr-menu-list:hover{
  background:#1c1c1c;
}
.dr-about{
  margin-left:9vw;
  
}
.dr-contact{
  margin-left:9vw;
}

@media only screen and (min-width: 768px) {
  
  .project{
    height:100vh;
    font-size: 2.7rem;
  }
  .about{
    height:90vh;
    font-size:4rem;
    justify-content:center;
    
  }
  .contact{
    height:70vh;
  }
  .desk-head{
    opacity:1;
    font-size:2rem;
    
    justify-content:space-betwewn;
  }
  .opo{
    margin-left:6vw;
  }
  .drawee-icon{
    opacity:0;
    pointer-events: none;
  }
  }