

.tx{
  height: 50vh;
  overflow: hidden;
  z-index: -1;
}


.bodyy{
  margin-top: 9vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.profile{
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}
.profile-pic{
  height: 150px;
  width: auto;
  scale: 1.5 ;
  
}
.profile-txt{
  text-align: left;
  font-weight: 800;
  font-size: 1.4rem;
  height: 7vh;
  display: flex;
  align-items: center;
}
.profile-txt3{
  font-weight: 800;
  font-size: 1.4rem;
}
.bluetick{
  margin-left: 5px;
  width: 20px;
  height: 20px;
  margin-top: 5px;
}

.body-txt{
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  text-align: center;
  opacity: 0.9;
  font-size: 1.2rem;
  letter-spacing: 0.002vw;
  align-items:center;
  justify-content: center;
  font-weight:350;
  font-family: Nunito;
}
.button{
  width: 40vw;
  height: 8vh;
  background: blue;
  text-align: center;
  align-content: center;
  margin-top: 8vh;
}
@media only screen and (min-width: 768px) {
  .body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0;
  
  }
  .button{
    background: green;
    font-family: Segoe UI;
  }
  .profile{
    height: 180px;
    width: 180px;
    border-radius: 50%;
    overflow: hidden;
    left: 0;
    margin-left: 0;
  }
  .profile-txt{
    opacity: 0;
  }
  .body-txt{
    margin-top: 0;
    right: 0;
    font-size: 2rem;
    
  }
}