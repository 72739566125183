.top{
  top: 0;
  height: 8vh;
  
}

.atxt-1{
  display: flex;
  text-align: center;
  font-size: 1.9rem;
  padding: 10px;
  font-weight: 800;
  margin-left: 2vw;
}
.atxt-2{
  color: #ff5823;
  margin-left: 10px;
  margin-right: 10px;
}
.atxt-3{
  font-size: 1.9rem;
  padding: 10px;
  font-weight: 800;
  margin-left: 2vw;
}
.atxt-4{
  font-size: 1.2rem;
  font-weight: 300;
  padding: 20px;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
}

.btn-ab{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
  height: 6vh;
  background: blue;
  border-radius: 10px;
}
.centerr{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card{
  height: 43vh;
  width: 90%;
  background: #162232;
  margin-top: 5vh;
}
.image{
  height: 59%;
  overflow: hidden;
  width: 100%;
  
}
.image-abo{
  width: 100%;
  height: auto;
}
.txta{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.btna{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.btnaa{
  width: 40%;
  height: 5vh;
  background: #26303a;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.image-star{
  height: auto;
  width: 10%;
  margin-right: 5vw;
}
.btnaa:nth-child(1){
  color:#16e516;
}
.btnaa:nth-child(2){
  color:#3f9fef;
}
.fn-12{
  font-weight: 900;
}
@media only screen and (min-width: 768px) {
  .centerr{
    display: inline-grid;
  grid-template-columns: auto auto;
  margin-left: 4vh;
  }
  .card{
  height: 25vh;
  width: 80%;
  background: #162232;
  margin-top: 5vh;
}
.txta{
  font-size: 2rem;
}
.btna{
  font-size: 1rem;
}
}