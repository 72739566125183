.Project{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.image-pro{
  width: 90%;
}
.txt-project{
  display: flex;
  font-size: 2.6rem;
  font-weight: 900;
}
.txt-project1{
  color: #ff5823;
  
}
.txt-project2{
  padding: 15px;
  text-align: left;
  font-family: Sans-Serif;
  font-weight: 250;
  font-size: 1.4rem;
  
}
.txt-4{
  margin-top: 10vh;
}
.txt-project3{
  display: flex;
  font-family: Sans-Serif;
  margin-left: 5vw;
  margin: 1vh;
  font-size: 1.2rem;
  font-weight: 500;
}
.txt-project4{
  color: #ff5823;
  font-weight: 800;
  margin-right: 5px;
  
}
@media only screen and (min-width: 768px) {
  .txt-project2{
    padding: 0;
    font-size: 2.5rem;
  }
  
}